import React from "react"
import Layout from "../components/Layout"
import { useStaticQuery, graphql, Link } from "gatsby"
import Image from "gatsby-image"
import SEO from "../components/SEO"
import { useLanguage } from "../contexts/LanguageContext"
const query = graphql`
  {
    file(relativePath: { eq: "pexels-cup-of-couple-6177605.jpg" }) {
      childImageSharp {
        fluid(
          sizes: "(max-width: 300px) calc(100vw - 25px), (max-width: 600px) calc(100vw - 15px), 1300px"
        ) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    otherFile: file(relativePath: { eq: "TeamViewer_Logo_Icon_Only.svg.png" }) {
      childImageSharp {
        fluid(maxWidth: 500, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

const Support = () => {
  const data = useStaticQuery(query)
  const { selectedLanguage } = useLanguage() // Use the useLanguage hook
  return (
    <Layout>
      <SEO title="Podrška" />
      <div className="img-wrapper img-wrapper-about ">
        <Image
          Tag="div"
          fluid={data.file.childImageSharp.fluid}
          className=" bcg bcg-about  "
        />
        {selectedLanguage === "EN" ? (
          <h2 className="about-header visibility-filter ">
            Download instructions
          </h2>
        ) : (
          <h2 className="about-header visibility-filter ">
            Upute za preuzimanje
          </h2>
        )}
        {selectedLanguage === "EN" ? (
          <h2 className="about-header mobile-visible">Support</h2>
        ) : (
          <h2 className="about-header mobile-visible">Podrška</h2>
        )}
      </div>
      {selectedLanguage === "EN" ? (
        <div className="about-content visibility-filter">
          <h4 className="about-paragraph support-paragraph ">
            <ol>
              <li>Click the TeamViewer icon (blue icon on the right)</li>
              <li>Now open the downloaded file and click "Run"</li>
              <li>
                After successfully opening Teamviewer, read your ID to the
                Administrator
              </li>
            </ol>
          </h4>
        </div>
      ) : (
        <div className="about-content visibility-filter">
          <h4 className="about-paragraph support-paragraph ">
            <ol>
              <li>Pritisnuti ikonu TeamViewera (plava ikona desno)</li>
              <li>Na sljedećem prozoru pritisnuti tipku “Run”</li>
              <li>
                Nakon što se program izvrši molimo dostaviti sistem
                administratoru “Vaš ID”
              </li>
            </ol>
          </h4>
        </div>
      )}
      {selectedLanguage === "EN" ? (
        <div className="about-content support-mobile-visible">
          <h4 className="about-paragraph  ">
            Support is currently only available for PCs!
          </h4>
        </div>
      ) : (
        <div className="about-content support-mobile-visible">
          <h4 className="about-paragraph  ">
            Podrška je trenutno dostupna samo za osobna računala!
          </h4>
        </div>
      )}

      <Link
        to="https://dl.dropboxusercontent.com/s/7dlra3rudbrsuk3/Udaljena%20podrska.exe?dl=0"
        className="teamviewer-img visibility-filter"
      >
        <Image fluid={data.otherFile.childImageSharp.fluid}></Image>
      </Link>
    </Layout>
  )
}

export default Support
